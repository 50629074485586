<template>
  <div class="space-y-4">
    <div class="flex items-center justify-between">
      <div class="flex items-center font-medium leading-none">
        <span>Auto-DM</span>
        <info-tooltip :content="tooltip"></info-tooltip>
      </div>
      <base-switch
        @input="updateEnabled"
        :disabled="disabled"
        v-model="isAutoDMEnabled"
      ></base-switch>
    </div>
    <div class="mb-6 mt-3 space-y-4" v-if="isAutoDMEnabled">
      <div
        v-if="['trial', 'none'].includes(userProfile.customerStatus)"
        class="mt-2 flex items-center leading-snug text-warning sm:space-x-2"
      >
        <inline-svg src="/img/icons/premium.svg" class="hidden sm:block" />
        <span
          >Auto-DMs won't be sent unless you
          <router-link to="/billing">Grab a Hypefury subscription</router-link></span
        >
      </div>

      <div
        v-if="
          userProfile.customerStatus === 'standard' &&
          userProfile.freeWeeklyAutoDMs &&
          userProfile.freeWeeklyAutoDMs.value === 0
        "
        class="mt-2 text-warning leading-snug flex items-center sm:space-x-2 font-bold"
      >
        <inline-svg src="/img/icons/premium.svg" class="hidden sm:block" />
        <span
          >You have run out of free Auto-DMs for the week. It will reset in
          {{ freeAutoDMsDaysLeft }} or you can
          <upgrade-to-premium-link
            >upgrade to {{ getPlanLabel('premium') }}</upgrade-to-premium-link
          >
          for unlimited Auto-DMs.</span
        >
      </div>

      <base-alert type="warning" radius="rounded-lg" class="my-4 flex items-center">
        Your tweet must clearly state that your followers would receive a DM if they reply or
        retweet (as per Twitter automation rules).
      </base-alert>

      <base-alert
        type="warning"
        radius="rounded-lg"
        class="my-4 flex items-center"
        v-show="userRanOutOfDailyDMs"
      >
        You've hit the limit of {{ autoDMLimits.successfulDMs }} Auto-DMs per day.<br />
        We're pausing the sending of DM's for you today to decrease spam signals. <br />
      </base-alert>
      <div class="items-left mt-2 flex flex-col font-bold leading-snug text-warning">
        <span> Please beware of Twitter's policies and limits regarding Direct Messages:</span>
        <ul class="mb-0 ml-6">
          <li>
            <a
              href="https://help.twitter.com/en/using-twitter/direct-message-faqs#why:~:text=Why%20am%20I%20having%20trouble%20sending%20Direct%20Messages%3F"
              target="_blank"
            >
              Why am I having trouble sending Direct Messages?
            </a>
          </li>
          <li>
            <a
              href="https://help.twitter.com/en/rules-and-policies/twitter-automation#:~:text=C.%20Automated%20Direct%20Messages"
              target="_blank"
            >
              Activity-specific Rules: Automated Direct Messages
            </a>
          </li>
        </ul>
      </div>
      <div class="font-bold text-warning">
        <p>After your tweet is published, the Auto-DM feature will be active for 3 days.</p>
        <p>Quoted tweets are not taken into account as retweets.</p>
        <p>Users who don't follow you won't get the DM.</p>
      </div>
      <div class="flex flex-col space-y-3">
        <label class="font-weight-medium text-primary">To receive the DM, user has to:</label>

        <div class="space-y-1">
          <base-radio
            v-model="selectedOptions"
            name="reply"
            data-cy="settings-inspiration-always"
            inline
          >
            Reply
          </base-radio>
          <base-radio
            v-model="selectedOptions"
            name="retweet"
            data-cy="settings-inspiration-always"
            inline
          >
            Retweet
          </base-radio>
          <base-radio
            v-model="selectedOptions"
            name="replyANDretweet"
            data-cy="settings-inspiration-always"
            inline
          >
            Reply and retweet
          </base-radio>
          <base-radio
            v-model="selectedOptions"
            name="replyORretweet"
            data-cy="settings-inspiration-always"
            inline
          >
            Reply or retweet <new-feature-badge class="feature-badge ml-1" />
          </base-radio>
        </div>
      </div>
      <div v-for="(message, idx) of messages" :key="idx" class="space-y-2">
        <div v-if="selectedOptions.includes('reply')" class="space-y-3">
          <label class="font-weight-medium text-primary">Reply content:</label>
          <input
            data-cy="reply-text"
            type="text"
            dir="auto"
            class="form-control"
            placeholder="If left blank, any reply will trigger the DM."
            v-model="message.reply"
            max="280"
          />
        </div>
        <div>
          <base-input
            v-if="selectedOptions.includes('reply') || idx === 0"
            ref="textarea-container"
            inputGroupClasses="textarea-container"
            alternative
          >
            <textarea
              placeholder="Write the DM content here..."
              ref="message"
              data-cy="auto-dm-textarea"
              dir="auto"
              class="form-control simple"
              :disabled="disabled"
              rows="3"
              v-model="message.content"
              maxlength="10000"
              @input="didUserEditMessage = true"
            ></textarea>
            <div class="thread-actions flex item-center p-1">
              <span class="p-1 text-dark-mode-15"
                >If you ever want to stop receiving my DMs, reply "STOP" at any time.</span
              >
              <div class="ml-auto flex items-center space-x-1">
                <div :class="charactersLeftClass(message.content)" class="character-count">
                  {{ charactersLeftCounter(message.content) }}
                </div>
              </div>

              <tooltip content="Remove Tweet" v-if="canDeleteMessage">
                <base-button
                  type="secondary"
                  data-cy="remove-tweet"
                  class="btn icon secondary small"
                  @click="deleteMessage(idx)"
                >
                  <inline-svg src="/img/icons/remove.svg" />
                </base-button>
              </tooltip>
            </div>
          </base-input>
          <p class="mt-4">Opt-out message is required to comply with Twitter automation rules.</p>
        </div>
      </div>

      <div v-if="selectedOptions.includes('reply')" class="flex items-baseline justify-between">
        <p class="text-xs text-muted">
          You can have multiple DMs, the message sent to the user depends on the reply.
        </p>

        <base-button
          type="secondary"
          data-cy="add-autoplug"
          class="btn text secondary small text-xs"
          @click="addReply"
        >
          Add
        </base-button>
      </div>

      <div v-if="userProfile.autoDMTags.length > 0" class="flex flex-wrap">
        <div
          class="custom-tags max-w-full overflow-hidden"
          v-for="autoDMTag of userProfile.autoDMTags"
          :key="autoDMTag.id"
        >
          <label>
            <input type="checkbox" :value="autoDMTag.id" v-model="selectedTags" />
            <span>{{ autoDMTag.name }}</span>
          </label>
        </div>
      </div>

      <NewAutoDMTag @add-tag="addTag" />

      <div v-if="isNewComposer" class="flex justify-end">
        <button
          class="btn text primary"
          :disabled="!canSaveAutoDMSettings"
          @click="sendValuesToParent(true)"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import NewAutoDMTag from '@/components/NewAutoDMTag';
  import NewFeatureBadge from '@/components/NewFeatureBadge';
  import lodash from 'lodash';
  import AutoDMMixin from '../../views/Mixins/AutoDMMixin.vue';
  import BaseRadio from '@/components/Inputs/BaseRadio';
  import CustomerStatusMixin from '../../views/Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'auto-dm',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      thread: {
        type: Object,
        required: false,
      },
      value: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: String,
        default: 'Send an automatic DM to your users when conditions are met!',
      },
      isNewComposer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isAutoDMEnabled: false,
        didUserEditMessage: false,
        triggerOptions: ['reply', 'retweet'],
        selectedOptions: 'replyANDretweet',
        messages: [{ reply: '', content: '' }],
      };
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      canDeleteMessage() {
        return this.messages.length > 1 && this.selectedOptions.includes('reply');
      },
      messagesToSave() {
        return this.selectedOptions.includes('reply')
          ? this.messages
          : [{ reply: '', content: this.messages[0].content }];
      },
      canSaveAutoDMSettings() {
        return (
          this.formattedSelectedOptions.split(',').length > 0 &&
          this.messagesToSave.every((message) => message.content.trim().length > 0)
        );
      },
      formattedSelectedOptions() {
        return this.selectedOptions.replace('AND', ',').replace('OR', ',');
      },
      requiresOnlyOneOption() {
        return this.selectedOptions.includes('OR');
      },
    },
    methods: {
      addReply() {
        this.messages = [...this.messages, { reply: '', content: '' }];
      },
      deleteMessage(messageIndex) {
        this.messages = this.messages.filter((_, idx) => idx !== messageIndex);
        setTimeout(() => {
          const refIndex = this.$refs.message[messageIndex] ? messageIndex : messageIndex - 1;
          const refToFocus = this.$refs.message[refIndex];
          const cursorPosition = this.messages[refIndex].content.length;
          refToFocus.focus();
          refToFocus.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
      },
      updateEnabled() {
        this.$emit('input', this.isAutoDMEnabled);
      },
      sendValuesToParent(isSaving) {
        if (this.isNewComposer && !isSaving) {
          return;
        }
        this.$emit(
          'values-updated',
          this.isAutoDMEnabled,
          this.formattedSelectedOptions.split(','),
          this.messagesToSave,
          this.selectedTags,
          this.requiresOnlyOneOption
        );
      },
      charactersLeftClass(message) {
        const MAX_LENGTH = 10000;
        if (message.length >= MAX_LENGTH) return 'character-count-exceeded';
        if (message.length > MAX_LENGTH - 100) return 'character-count-warning';
        return 'bg-light';
      },
      charactersLeftCounter(message) {
        return message.length;
      },
    },
    mounted() {
      if (this.thread && this.thread.autoDM) {
        this.isAutoDMEnabled = true;
        this.messages = this.thread.autoDM.message
          ? [{ reply: '', content: this.thread.autoDM.message }]
          : this.thread.autoDM.messages;
        this.selectedOptions = this.thread.autoDM.requiresOnlyOneOption
          ? this.thread.autoDM.triggerOptions.join('OR')
          : this.thread.autoDM.triggerOptions.join('AND');
        this.selectedTags = lodash.get(this.thread, 'autoDM.tags', []);
        this.updateEnabled();
      }

      this.$emit(
        'updateAutoDMFromParentToChildFunction',
        (triggerOptions, messages, tags, requiresOnlyOneOption) => {
          this.isAutoDMEnabled = true;
          this.selectedOptions = requiresOnlyOneOption
            ? triggerOptions.join('OR')
            : triggerOptions.join('AND');
          this.messages = lodash.cloneDeep(messages);
          this.selectedTags = lodash.clone(tags);
        }
      );
    },
    watch: {
      value(val) {
        this.isAutoDMEnabled = val;
      },
      disabled(val) {
        if (val) {
          this.isAutoDMEnabled = false;
          this.updateEnabled();
        }
      },
      messages: {
        deep: true,
        handler: function (val, old) {
          if (Array.isArray(old) && val.length > old.length) {
            setTimeout(() => {
              this.$refs.message[val.length - 1].focus();
              this.$refs.message[val.length - 1].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            }, 0);
          }
          this.sendValuesToParent();
        },
      },
      selectedOptions() {
        this.sendValuesToParent();
      },
      isAutoDMEnabled(val) {
        if (val) {
          this.$nextTick(() => this.$refs.message[0].focus());
        } else {
          this.sendValuesToParent();
        }
      },
      selectedTags() {
        this.sendValuesToParent();
      },
    },
    components: {
      NewAutoDMTag,
      BaseRadio,
      NewFeatureBadge,
    },
    filters: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
    },
    mixins: [AutoDMMixin, CustomerStatusMixin],
  };
</script>
